<template>
  <div>
    <div class="cardTop">
      <div class="flag-container">
        <img class="flag" src="../../assets/img/chineseFlag.png" alt="" />
        <div class="flag-mask"></div>
      </div>
      <div class="first-line">
        <div class="account-types">{{ accountTypes }}</div>
        <div class="account-text">可用余额</div>
      </div>
      <div class="avaliable-money marginTop10">{{ avaliableMoney }}
        <div class="shortCode">{{shortCode}}</div>
      </div>
      <div class="account-text2 marginTop32">账号</div>
      <div class="account-text2 marginTop10">{{ accountNum }}</div>
    </div>
    <div class="cardBottom">
      <span class="btn">交易</span>
      <span class="btn">充值</span>
      <span class="btn">转账</span>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    accountTypes: {
      type: String,
      default: 'Primary account'
    },
    avaliableMoney: {
      type: Number,
      default: 123123
    },
    accountNum: {
      type: Number,
      default: 11023345
    },
    shortCode: {
      type: String,
      default: 'CNY'
    }
  }
}

</script>

<style scoped>
.cardTop {
  width: 860px;
  height: 166px;
  border-radius: 4px 4px 0px 0px;
  box-sizing: border-box;
  padding: 32px 84px 0 32px;
  background-color: #ffffff;
  /* background:#FFFFFF url('../../assets/img/chineseFlag.png')no-repeat 828px 0px / 32px 26px; */
  position: relative;
  overflow: hidden;
}
.cardBottom {
  width: 860px;
  height: 80px;
  background: #ffffff;
  border-radius: 0px 0px 4px 4px;
  margin-top: 1px;
  font-weight: 400;
  color: #bc9a41;
  line-height: 80px;
  padding-right: 48px;
}
.first-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.account-types {
  font-size: 24px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
}
.account-text {
  font-size: 24px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
  line-height: 28px;
}
.avaliable-money {
  float: right;
  font-size: 24px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  position: relative;
}
.btn {
  margin-left: 76px;
  float: right;
  text-decoration: underline;
  cursor: pointer;
  padding: 0 20px;
}
.flag-container {
  position: absolute;
  width: 32px;
  height: 26px;
  right: 0;
  top: 0;
}
.flag-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  border-width: 13px 16px;
  border-style: solid;
  border-color: transparent transparent #ffffff #ffffff;
  box-sizing: border-box;
}
.flag {
  position: absolute;
  width: 100%;
  height: 100%;
}
.shortCode{
  position: absolute;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
  top: -8px;
  right: -33px;
}
@media only screen and (max-width:600px) {
.account-types {
  font-size: 22px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  }
  .cardTop {
  width: auto;
  height: auto;
  border-radius: 4px 4px 0px 0px;
  box-sizing: border-box;
  padding: 5vw 5vw 5vw 5vw;
  background-color: #ffffff;
  /* background:#FFFFFF url('../../assets/img/chineseFlag.png')no-repeat 828px 0px / 32px 26px; */
  position: relative;
  overflow: hidden;
}
.cardBottom {
  width: auto;
  height: 7vh;
  background: #ffffff;
  border-radius: 0px 0px 4px 4px;
  margin-top: 1px;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  padding: 0;
}
.btn {
  margin-left: 0;
  float: right;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
}
.account-text {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
  line-height: 28px;
}
.marginTop32{
  margin-top: 2vw;
}
.avaliable-money {
  float: right;
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  position: relative;
}
.shortCode{
  position: absolute;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
  top: -18px;
  right: -10px;
}
}
</style>
