<template>
  <div>
    <div class="top-bar pb-2vh">
      <div class="account-title">我的账户</div>
    </div>
    <div class="user-subPage-bottom">
      <AccountCard></AccountCard>
    </div>
  </div>
</template>

<script>
import AccountCard from '@/components/User/AccountCard.vue'

export default {
  components: {
    AccountCard
  },
  methods: {
  }
}

</script>

<style scoped>

</style>
